<template>
  <div class="container-fluid" style="position: relative; top: 10px">
    <i
      class="el-icon-back"
      style="font-size: 30px; cursor: pointer"
      @click="back()"
    ></i>
  </div>
</template>
<script>
export default {
  name: "Navbar",
  methods: {
    back: function () {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.nav-bar {
  width: 100%;
  margin: 0;
  padding: 0;
  margin-top: 30px;
  height: 100px;
  background-color: #909399;
}
</style>