<template>
  <div class="main-container" style="background-color: #eff5fb">
    <Nav-bar />
    <h1 style="text-align: center">
      Order {{ this.IdOrder != -1 ? "#" + this.IdOrder : "" }}
    </h1>
    <div class="container-fluid list-container">
      <div class="title">
        <div v-if="editMode === true" class="container-fluid">
          <i class="el-icon-back" style="
              font-size: 25px;
              position: absolute;
              top: 60px;
              left: 8%;
              color: #6493c2;
              cursor: pointer;
            " @click="switchMode()"></i>
          <div class="category-container">
            <div v-for="prod in current_cat" :key="prod.Id">
              <el-button @click="addProduct(prod)" class="cat-btn" type="primary">
                {{ prod.Name }}
              </el-button>
            </div>
          </div>
        </div>

        <div v-if="editMode === false" class="category-container">
          <div v-for="cat in Info.Category" :key="cat.Id">
            <el-button class="cat-btn" @click="switchMode(cat)" type="primary">{{ cat.Name }}</el-button>
          </div>
        </div>
        <div class="products-container">
          <div class="product-content">
            <!-- aici vine tabela -->

            <table class="table-list">
              <thead>
                <tr>
                  <th>Name</th>
                  <th style="text-align: right">Price</th>
                </tr>
              </thead>
              <tbody v-for="(product, i) in Order.Products" :key="product.Id + i">
                <tr>
                  <td>{{ product.Name }}</td>
                  <td style="text-align: right">
                    {{ (product.Price * 1).toFixed(2) }} $
                  </td>
                </tr>
                <tr style="font-size: 10px; padding 0px 10px" v-if="product.Extra.length > 0">
                  <td>
                    Extra: {{ product.Extra.map((e) => e.Name).join(", ") }}
                  </td>
                  <td style="text-align: right">
                    {{
                      product.Extra.map((e) => Number(e.Price))
                        .reduce((p, i) => p + i, 0)
                        .toFixed(2)
                    }}
                    $
                  </td>
                </tr>
                <tr style="font-size: 10px; padding 0px 10px" v-if="product.Methods !== ''">
                  <td colspan="2">Methods: {{ product.Methods }}</td>
                </tr>

                <tr>
                  <td colspan="2" style="text-align: right">
                    <div style="
                        width: 100%;
                        margin: 0;
                        padding: 0;
                        diplay: flex;
                        justify-content: center;
                      ">
                      <el-button @click="add_methods(i, Info.Methods)" class="list-btn">Methods</el-button>
                      <el-button @click="add_extra(i, Info.Extra)" class="list-btn">Extra</el-button>
                      <el-button type="danger" @click="deleteItem(i)" class="list-btn">Delete</el-button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="" style="position: absolute; bottom: 20px">
        <h3 style="text-align: center">
          TOTAL:
          {{
            this.Order.Products
              ? this.Order.Products.map((p) => {
                let price = Number(p.Price);
                let extra = p.Extra
                  ? p.Extra.map((e) => Number(e.Price)).reduce(
                    (e, i) => e + i,
                    0
                  )
                  : 0;
                return price + extra;
              })
                .reduce((p, i) => p + i, 0)
                .toFixed(2)
              : 0
          }}
          $
        </h3>
        <div class="footer-btn">
          <el-button type="danger" v-if="mode === 'edit'" @click="delete_order()">Cancel Order</el-button>
          <el-button :type="mode === 'edit' ? 'warning' : 'success'" @click="save()">Send Order</el-button>
          <el-button @click="payment()" type="success" v-if="mode === 'edit'">Finish order</el-button>
        </div>
      </div>
    </div>
    <Methods-dialog ref="methods_dlg" @save="refresh_info()" @onSave="addMethods" />
    <Extra-dialog class="custom-width" ref="extra_dlg" @save="refresh_info()" @onSave="addExtra" />
    <div v-show="mobile" class="rotateScreen">Rotate the screen!</div>
  </div>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Nav from "@/pages/frontend/Nav.vue";
import Methods from "@/pages/frontend/order/Methods.vue";
import Extra from "@/pages/frontend/order/Extra.vue";
import axios from "axios";

export default {
  name: "Order",
  components: {
    "Nav-bar": Nav,
    "Methods-dialog": Methods,
    "Extra-dialog": Extra,
  },
  extends: BasePage,
  data() {
    return {
      IdOrder: -1,
      mobile: false,
      btnName: "",
      IdTable: null,
      changes: false,
      mode: "add",
      loadingVisible: false,
      connection: null,
      Order: {},
      editMode: false,
      Initial_order: {},
      current_cat: null,
      Info: {
        Category: [],
        Methods: [],
        Extra: [],
      },
      currentRow: null,
    };
  },

  created: async function () {
    // let key = "c18824492815052fa2425d036a05e021";
    // let url = `wss://socketsbay.com/wss/v2/100/${key}/`;
    // this.connection = new WebSocket(url);
    // this.connection.onmessage = (event) => {};
    // this.connection.onopen = (event) => {
    //   console.log("APLICATIA A FOST CONECTAT LA WEBSOCKET");
    // };
  },

  methods: {
    async switchMode(cat) {
      this.editMode = !this.editMode;
      if (cat === null) this.current_cat = null;
      if (this.editMode === true) {
        this.current_cat = cat.Products;
      }
    },

    addMethods(data) {
      let methods = data.methods;
      let index = data.index;
      var product = this.Order.Products[index];
      product.Methods = methods;
      this.changes = true;
    },

    addExtra(data) {
      let extra = data.extra;
      let index = data.index;
      let product = this.Order.Products[index];
      product.Extra = extra;
      this.changes = true;
      console.log(data);
    },

    deleteItem(data) {
      this.Order.Products.splice(data, 1);
      this.changes = true;
    },

    async add_methods(index, list) {
      let data = this.Order.Products[index].Methods;
      if (list === null) {
        list = this.Info.Methods;
      }
      this.$refs.methods_dlg.show_methods(data, list, index);
    },

    async add_extra(index, list) {
      let data = this.Order.Products[index].Extra;
      if (list === null) {
        list = this.Info.Extra;
      }
      this.$refs.extra_dlg.show_extra(data, list, index);
    },

    async addProduct(product) {
      this.Order.Products.unshift({
        Amount: "1",
        Extra: [],
        Id: product.Id,
        Methods: "",
        Name: product.Name,
        OrderNumber: product.OrderNumber,
        Price: product.Price,
      });
      this.changes = true;
    },

    async refresh_info() {
      this.loadingVisible = true;
      if (this.mode === "edit") {
        const res = await this.post("order/get_order_by_id", {
          Id: this.IdOrder,
        });

        this.Initial_order = {
          ClosedAt: res.Order.ClosedAt,
          CreatedAt: res.Order.CreatedAt,
          Closed: 0,
          Id: res.Order.Id,
          IdTable: res.Order.IdTable,
          Products: [...res.Order.Products],
        };

        console.log(this.Initial_order.Products);

        this.Order = {
          ClosedAt: res.Order.ClosedAt,
          CreatedAt: res.Order.CreatedAt,
          Closed: 0,
          Id: res.Order.Id,
          IdTable: res.Order.IdTable,
          Products: res.Order.Products,
        };
      } else {
        let obj = {
          ClosedAt: 0,
          Closed: 0,
          CreatedAt: 0,
          IdTable: this.IdTable,
          Products: [],
        };
        this.Order = obj;
      }
      const category = await this.post("order/get_data");
      this.Info.Category = category;
    },

    async get_info() {
      let response = await this.post("order/get_info_for_dialog");
      response.methods.forEach((methods) => {
        this.Info.Methods.push(methods);
      });

      response.extra.forEach((extra) => {
        this.Info.Extra.push(extra);
      });
    },

    delete_order() {
      let id = this.IdOrder;
      let table = this.Order.IdTable;
      this.$confirm("Are you sure you want to cancel this order?", "Warning", {
        confirmButtonText: "Cancel Order",
        cancelButtonText: "Close",
        type: "warning",
      })
        .then(async () => {
          let data = await this.post("order/delete_item", {
            Id: id,
            IdTable: table,
          });

          this.$router.push("/app/tables/");
          this.$message({
            type: "success",
            message: "Order canceled!",
          });
        })
        .catch(() => { });
    },

    async payment() {
      if (this.changes) {
        let idTable = this.IdTable;
        let order = this.Order;
        let mode = this.mode;
        let object = {
          idTable: idTable,
          mode: mode,
          object: order,
        };
        object.object.Id = this.IdOrder;
        let data = await this.post("order/save_order", object);

      }
      // await this.connection.close();
      this.$router.push("/app/payment/" + this.IdOrder);
      this.changes = false;

    },

    async printer(date) {
      var products = [];
      console.log('Printeaza!')
      if (this.mode === "edit") {
        let initial_products = this.Initial_order.Products.length;
        let current_products = this.Order.Products.length;
        if (current_products > initial_products) {
          products = this.Order.Products.slice(
            0,
            current_products - initial_products
          );
        } else {
          products = this.Order.Products;
        }
      } else {
        products = this.Order.Products;
      }

      let raw_vat = await this.post("order/get_vat");
      let vat = Number(raw_vat.VAT[0].Value);
      let subtotal = this.Order.Products
        ? this.Order.Products.map((p) => {
          let price = Number(p.Price);
          let extra = p.Extra
            ? p.Extra.map((e) => Number(e.Price)).reduce((e, i) => e + i, 0)
            : 0;
          return price + extra;
        })
          .reduce((p, i) => p + i, 0)
          .toFixed(2)
        : 0;
      let total = ((vat / 100 + 1) * subtotal).toFixed(2);
      date = date.split(" ");

      let data = {
        OrderId: this.Order.Id,
        Table: this.Order.IdTable,
        Products: products,
        CreatedAt: date[0],
        Time: date[1],
        Subtotal: subtotal,
        Vat: ((vat / 100) * total).toFixed(2),
        Total: total,
        Tips: 0,
      };

      let res = await this.post("order/get_table_name", {
        IdTable: data.Table,
      });
      data.Table = res[0].Name;

      // await this.post("order/print", {
      //   OrderId: data.OrderId,
      //   Table: data.Table,
      //   Products: data.Products,
      //   CreatedAt: data.CreatedAt,
      //   Time: data.Time,
      //   Subtotal: data.Subtotal,
      //   Vat: data.Vat,
      //   Total: data.Total,
      //   Tips: data.Tips,
      // });

      await this.post("order/print", {
        OrderId: data.OrderId,
        Table: data.Table,
        Products: data.Products,
        CreatedAt: data.CreatedAt,
        Time: data.Time,
        Subtotal: data.Subtotal,
        Vat: data.Vat,
        Total: data.Total,
        Tips: data.Tips,
      });
    },

    async save() {
      if (!this.Order.Products || this.Order.Products.length <= 0) {
        return this.$message({
          type: "error",
          message: "Please insert at least one product!",
        });
      }
      let idTable = this.IdTable;
      let order = this.Order;
      let mode = this.mode;
      let object = {
        idTable: idTable,
        mode: mode,
        object: order,
      };
      if (mode === "add") {
        this.$confirm(
          "Your order will be created, you can edit it anytime. Do you want to continue?",
          "Warning",
          {
            confirmButtonText: "Place Order",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            let data = await this.post("order/save_order", object);
            this.Order.Id = data.order_id;
            this.$router.push("/app/tables/");

            this.$message({
              type: "success",
              message: "Order created!",
            });
            // console.log(data);
            await this.printer(data.createdAt);
            await this.printer(data.createdAt);

          })
          .catch((err) => {
            // this.$message({
            //   type: "info",
            //   message: "Order canceled",
            // });
            console.log(err);
          });
      } else {
        object.object.Id = this.IdOrder;
        this.$confirm(
          "Your order will be edited. Do you want to continue?",
          "Warning",
          {
            confirmButtonText: "Edit Order",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            let data = await this.post("order/save_order", object);
            this.$message({
              type: "success",
              message: "Order updated!",
            });
            this.printer(data.createdAt);
            this.$router.push("/app/tables/");
          })
          .catch((e) => {
            console.log(e);
          });
      }
      this.changes = 0;
    },
  },
  mounted() {
    // let token = settings.get_token();
    // if (token == null) {
    //   this.$router.push("/app/tables/").catch(() => {});
    //   return;
    // }

    this.IdOrder = !this.$route.params.id.startsWith("new")
      ? this.$route.params.id
      : -1;

    if (this.IdOrder === -1) {
      this.mode = "add";
      this.btnName = "Place Order";
      this.IdTable = this.$route.params.id.replace("new", "");
    } else {
      this.mode = "edit";
      this.btnName = "Edit Order";
    }

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.mobile = true;
    }

    this.refresh_info();
    this.get_info();
  },
};
</script>

<style scoped>
.table-list {
  margin: 0;
  padding: 0;
  width: 100%;
}

.main-container {
  max-height: 100vh;
  overflow: hidden;
}

.list-btn {
  width: 70px;
  padding: 3px 15px;
  text-align: center;
  font-size: 10px;
  height: 21px;
}

.category-container {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  min-height: 16vh;
  overflow: hidden;
  overflow-y: scroll;
}

.list-container {
  margin: 0;
  padding: 15px 5px;
  width: 100% !important;

  display: flex;
  justify-content: center;
  background-color: #eff5fb;
  gap: 5px;
  flex-wrap: wrap;
  overflow: hidden;
  overflow-y: scroll;
  min-height: 100vh;
}

.products-container {
  position: relative;
  margin: 0;
  padding: 10px;
  width: 80%;
  max-height: 50vh;
  overflow: scroll;
  margin-top: 50px;
  border-radius: 4px;
  background-color: #dde9f5;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.3rem;
}

.product-content {
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  background-color: #dde9f5;
  max-height: 100%;
}

.product-title {
  height: 60px;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  width: 100%;
  height: 10vh;
}

.cat-btn {
  background-color: #6493c2;
  border-color: #6493c2;
  min-width: 30vw;
  height: 42px;
  margin: 0px;
  padding: 7px;
  font-size: 1.2rem;
}

.footer-container {
  min-height: 100%;
  margin: 0;
  padding: 0;
  width: 80%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

@media only screen and (max-width: 700px) {
  /* Pentru telefon: */

  .main-container {
    max-height: 100vh;
    overflow: hidden;
  }

  .footer-container {
    width: 86%;
  }

  .list-container {
    max-height: 90vh;
  }

  .title {
    width: 100%;
    height: 10vh;
  }

  .category-container {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    max-height: 16vh;
  }

  .products-container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 40vh;
    overflow: auto;
    width: 90%;
    font-size: 0.9rem;
  }

  .footer-btn {
    margin: 0;
    padding: 0;
    justify-content: center;
  }

  .footer-btn .el-button {
    width: 100px;
    text-align: center;
    font-size: 10px;
  }

  .cat-btn {
    text-align: center;
    background-color: #6493c2;
    border-color: #6493c2;

    width: 40vw;
    min-width: 40vw;
    overflow: hidden;
    padding: 7px;
    font-size: 3.5vw;
  }
}

.footer-btn {
  display: flex;
  justify-content: flex-end;
}

@media (orientation: landscape) {
  .rotateScreen {
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
      0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    font-size: 3vw;
    color: white;
    font-weight: bold;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0vh;
    left: 0vw;
    height: 100vh;
    width: 100vw;
    background-color: #6493c2;
  }
}
</style>