<template>
  <el-dialog
    title="Extra"
    :visible.sync="showPopup"
    class="dialog-class custom-width"
    append-to-body
    id="test"
    fullscreen="true"
  >
    <div id="ec" class="element-container">
      <el-input
        v-model.lazy="search"
        @change="handleChange"
        placeholder="Search..."
      ></el-input>
      <hr />
      <ul class="list-body">
        <li
          v-for="(extra, i) in Info.Extra"
          :id="extra.Id"
          :key="extra.Id + i"
          @click="change(extra)"
          class="noselect"
        >
          <div><i class="el-icon-star-on"> </i> {{ " " + extra.Name }}</div>
          <span>{{ extra.Price }} $</span>


        </li>
      </ul>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()"> Close </el-button>
      <el-button @click="sendData()" type="primary"> Save </el-button>
    </span>
  </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";

export default {
  name: "Extra_dialog",
  extends: BasePage,

  data() {
    return {
      baseUrl: "",
      showPopup: false,
      mode: "add",
      index: null,
      search: "",
      Info: {
        Extra: [],
      },
      backUpInfo: {
        Extra: [],
      },
      Data: [],
    };
  },

  watch: {
    search: async function (val) {
      if (val.startsWith(" ")) {
        this.search = this.search.replace(" ", "");
      }
      await this.handleChange();
      await this.refresh();
    },
  },

  methods: {
    async handleChange() {
      this.Info.Extra = [...this.backUpInfo.Extra];
      const data = this.Info.Extra.filter((extra) =>
        extra.Name.toLowerCase().startsWith(this.search.toLowerCase())
      );
      this.Info.Extra = data;
      await this.refresh();
    },

    sendData() {
      let data = { extra: this.Data, index: this.index };

      this.$emit("onSave", data);
      this.Data = [];
      this.Info.Extra = [];
      this.showPopup = false;
    },

    close() {
      this.Data = [];
      this.Info.Extra = [];
      this.showPopup = false;
    },

    async show_extra(data, list, index) {
      this.refresh();
      this.showPopup = true;
      this.Info.Extra = [];
      list.forEach((extra) => {
        this.Info.Extra.push(extra);
      });

      this.Info.Extra = list;
      this.backUpInfo.Extra = list;
      this.Data = [...data];
      this.index = index;
      await setTimeout(this.refresh, 10);
    },

    refresh() {
      if (this.Data.length > 0) {
        this.Info.Extra.forEach((extra) => {
          if (this.Data.find((e) => e.Id === extra.Id)) {
            let doc = document.getElementById(extra.Id);
            if (doc) doc.classList.add("selected");
          } else {
            let doc = document.getElementById(extra.Id);
            if (doc && doc.classList.contains("selected"))
              doc.classList.remove("selected");
          }
        });
      } else {
        this.Info.Extra.forEach((extra) => {
          let doc = document.getElementById(extra.Id);
          if (doc) doc.classList.remove("selected");
        });
      }
    },

    removeExtra(data) {
      if (this.Data.find((e) => e.Id === data.Id)) {
        this.Data = this.Data.filter((extra) => extra.Id !== data.Id);
      }
    },

    change(data) {
      let doc = document.getElementById(data.Id);
      if (this.Data.find((e) => e.Id === data.Id)) {
        doc.classList.remove("selected");
        this.Data = this.Data.filter((extra) => extra.Id !== data.Id);
      } else {
        doc.classList.add("selected");

        this.Data.push(data);
      }
      this.search = "";
    },
  },
  mounted: function () {
    this.baseUrl = settings.BASE_URL;
  },
};
</script>

<style lang="less" scoped>
.full-width {
  width: 100% !important;
}

.element-container {
  overflow-y: hidden;
  height: 60vh;
  width: 100% !important;
}

.list-body {
  margin: 0;
  padding: 0 20px;
  list-style: none;
  font-size: 18px;
  height: 60vh;
  overflow: hidden;
  overflow-y: scroll;
}

@media only screen and (max-width: 600px) {
  .dialog-class {
    width: 100% !important;

  }

  .extra-btn {
    min-width: 50px;
  }
}

.list-body li {
  width: 100%;
  margin: 0;
  font-size: 20px;
  padding: 3px 3px;
}

.list-body li:hover {
  background-color: rgb(238, 238, 238);
  border-radius: 10px;
}

.list-body li {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.selected {
  color: rgb(16, 157, 212);
}

.visible {
  display: none;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>